import React from "react";
import { Link } from "gatsby"
import Bio from "./bio";
import { baseTypography, h1, h1Small, h3 } from "../base/typography.css";
import { invisibleLink } from "../base/links.css";
import { globalFooter } from "./footer/footer.css";
import { layoutGrid } from "./grid/grid.css";
import headerClass from "./header/header.css";
import { baseLayout, wrapper } from "./layout/layout.css";

interface LayoutProps {
  location: Location;
  title: string;
  sidebar?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ location, title, children, sidebar }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1 className={h1}>
        <Link
          to={`/`}
          className={invisibleLink}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h1 className={h1Small}>
        <Link
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  }
  return (
    <div className={`${baseTypography} ${baseLayout}`}>
      <div className={layoutGrid}>
      <header className={headerClass}>
        <div className={wrapper}>
          {header}
        </div>
      </header>
      <main>{children}</main>
      {sidebar && (
        <aside>{sidebar}</aside>
      )}
      </div>
    <footer className={globalFooter}>
      <div className={wrapper}>
        <Bio />
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.org" target="_blank" rel="noopener noreferrer">Gatsby</a>
        {` `}and <a href="https://vanilla-extract.style/" target="_blank" rel="noopener noreferrer">vanilla-extract</a>.
      </div>
    </footer>
    </div>
  )
}

export default Layout
